<template>
  <v-container class='pt-7 pb-11'>
    <h1 class='text-h4 mb-5'>{{ 'generalTextData'|localize }}</h1>
    <div class='white elevation-2 rounded'>
      <v-row class='ma-0' align='center'>
        <v-col cols='4'>
          <div class='subtitle-2'>{{ 'notificationOnClientUI'|localize }} - {{ 'highlightedText'|localize }}</div>
          <i class='text-caption'>{{ 'setsHighlightedNotification'|localize }}</i>
        </v-col>
        <v-col cols='4'>
          <v-text-field
            v-model='highlighted_text_se'
            outlined
            hide-details
            dense
            label='Swedish'
          />
        </v-col>
        <v-col cols='4'>
          <v-text-field
            v-model='highlighted_text_en'
            outlined
            hide-details
            dense
            label='English'
          />
        </v-col>
      </v-row>
      <v-row class='ma-0' align='center'>
        <v-col cols='4'>
          <div class='subtitle-2'>{{ 'notificationOnClientUI'|localize }} - {{ 'generalText'|localize }}</div>
          <i class='text-caption'>{{ 'setsGeneralNotification'|localize }}</i>
        </v-col>
        <v-col cols='4'>
          <v-text-field
            v-model='general_text_se'
            outlined
            hide-details
            dense
            label='Swedish'
          />
        </v-col>
        <v-col cols='4'>
          <v-text-field
            v-model='general_text_en'
            outlined
            hide-details
            dense
            label='English'
          />
        </v-col>
      </v-row>
      <v-row class='ma-0'>
        <v-col cols='auto' class='ml-auto'>
          <v-btn
            depressed
            rounded
            color='primary'
            :loading='saveLoading'
            :disabled='saveLoading'
            @click='saveNotifications'
          >
            {{ 'save'|localize }}
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
  import api from '@/api/api';
  import localize from '@/filters/localize';
  import methods from '@/helpers/methods';

  export default {
    name: 'AdminGeneralTextData',

    data() {
      return {
        saveLoading: false,
        highlighted_text_en: '',
        highlighted_text_se: '',
        general_text_en: '',
        general_text_se: '',
      };
    },

    mounted() {
      this.setValues();
    },

    computed: {
      apiLocales() {
        return this.$store.getters.apiLocales;
      }
    },

    methods: {
      setValues() {
        this.apiLocales.highlighted_text_en ? this.highlighted_text_en = this.apiLocales.highlighted_text_en : this.highlighted_text_en = '';
        this.apiLocales.highlighted_text_se ? this.highlighted_text_se = this.apiLocales.highlighted_text_se : this.highlighted_text_se = '';
        this.apiLocales.general_text_en ? this.general_text_en = this.apiLocales.general_text_en : this.general_text_en = '';
        this.apiLocales.general_text_se ? this.general_text_se = this.apiLocales.general_text_se : this.general_text_se = '';
      },
      queryData() {
        let queryData = {};
        this.highlighted_text_en && (queryData.highlighted_text_en = this.highlighted_text_en);
        this.highlighted_text_se && (queryData.highlighted_text_se = this.highlighted_text_se);
        this.general_text_en && (queryData.general_text_en = this.general_text_en);
        this.general_text_se && (queryData.general_text_se = this.general_text_se);
        return queryData;
      },
      async saveNotifications() {
        try {
          this.saveLoading = true;
          await api.setNotifications(this.queryData());
          let notificationsData = await api.getNotifications();
          this.$store.commit('changeApiLocales', notificationsData.data);
          this.setValues();
          methods.showGlobalSnackbar('success', localize('done'));
        } catch (e) {
          methods.showGlobalSnackbar('error', methods.getErrors(e.response));
        } finally {
          this.saveLoading = false;
        }
      }
    }
  };
</script>
